<template>
  <v-container>
    <v-card class="mx-auto col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <v-card-title class="d-flex justify-space-around">
        <h1 class="font-weight-medium text-center">
          Reporte de documentos cargados
        </h1>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col class="d-flex flex-wrap" cols="12" xs="12" sm="3" md="3">
            <span
              class="
                text-subtitle-2
                col-lg-12 col-md-12 col-sm-12 col-xs-12
                pt-0
                pb-0
              "
              :style="{ color: 'initial !important' }"
              >Rango de fechas</span
            >

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-0 pb-0">
              <v-menu
                v-model="date_radicado.estado"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_radicado.value"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    hide-details
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  scrollable
                  v-model="date_radicado.value"
                  range
                  class="pa-0"
                  locale="es"
                >
                </v-date-picker>
              </v-menu>
            </div>
          </v-col>

          <v-col class="d-flex flex-wrap" cols="12" xs="12" sm="2" md="2">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-0 pb-0">
              &nbsp;
            </div>

            <div
              class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right pa-0"
            >
              <v-btn class="light-blue darken-1 white--text" @click="getItems">
                consultar
                <v-icon dark> &nbsp; mdi-magnify </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="array_table"
          item-key="_id"
          show-expand
          :single-expand="true"
          :expanded.sync="expanded"
          class="my-5"
        >
          <template v-slot:top>
            <v-toolbar flat class="d-flex justify-content-end">
              <v-btn
                v-if="false"
                color="red darken-2"
                dark
                @click="create_file('pdf')"
              >
                Pdf &nbsp;
                <v-icon dark> mdi-file-pdf </v-icon>
              </v-btn>

              <v-divider v-if="false" class="mx-4" inset vertical></v-divider>
              <v-btn color="green darken-2" dark @click="create_file('excel')">
                Excel &nbsp;
                <v-icon dark> mdi-file-excel </v-icon>
              </v-btn>
              <!--
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn color="green darken-2" dark @click="create_file('csv')">
                Csv &nbsp;
                <v-icon dark> mdi-file-delimited </v-icon>
              </v-btn>
              -->
            </v-toolbar>
          </template>
          <template #[`item.log_id_adj`]="{ item }">
            <div>
              {{ data_adj(item).adj_nmbre }}
            </div>
          </template>

          <template #[`item.log_upload`]="{ item }">
            <div>
              <span
                :class="
                  item.log_upload
                    ? 'pa-1 rounded-lg green lighten-1 white--text text-subtitle-2'
                    : 'pa-1 rounded-lg red lighten-1 white--text text-subtitle-2'
                "
              >
                {{ item.log_upload ? "Aceptado" : "Rechazado" }}
              </span>
            </div>
          </template>

          <template #[`item.log_fecha`]="{ item }">
            <div>
              {{ item.log_fecha | formatDate }}
            </div>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="py-2">
              <v-row class="grey lighten-5 mb-3 pa-2">
                <v-col class="font-weight-bold">Nro</v-col>
                <v-col class="font-weight-bold">Archivo</v-col>
                <v-col class="font-weight-bold">Estado</v-col>
                <v-col class="font-weight-bold">Mensaje</v-col>
              </v-row>

              <v-row
                v-for="adj in item.log_adjuntos"
                :key="adj._id"
                class="grey lighten-5 mb-2 pa-2"
              >
                <v-col>{{ adj.idx.adj_nmro }}</v-col>
                <v-col>
                  <span v-if="isPdf(adj.idx.adj_nmbre)">
                    <a href="#" @click="viewPdf(item.dcm_id, adj.idx)">
                      <i class="far fa-file-pdf" style="font-size:18px;color:red"></i>
                    </a>
                    <img v-if="adj.idx.download" src="/images/loading.gif" />
                  </span>
                  <span v-if="!isPdf(adj.idx.adj_nmbre)">                            
                    <a href="#" @click="downloadFile(item.dcm_id, adj.idx)">
                      <i class="fas fa-download" style="font-size:18px;color:blue"></i>
                    </a>                                                          
                    <v-progress-circular
                      v-if="adj.idx.download"
                      :rotate="-90"
                      :size="30"
                      :width="3"
                      :value="adj.idx.downloadPercentage"
                      color="primary"
                    >
                      {{ adj.idx.downloadPercentage }}
                    </v-progress-circular>
                  </span>
                  <span>
                    {{ adj.idx.adj_nmbre }}
                  </span>                     
                </v-col>
                <v-col>
                  <span
                    :class="
                      item.log_upload
                        ? 'pa-1 rounded-lg green lighten-1 white--text text-subtitle-2'
                        : 'pa-1 rounded-lg red lighten-1 white--text text-subtitle-2'
                    "
                  >
                    {{ adj.status ? "Aceptado" : "Rechazado" }}
                  </span>
                </v-col>
                <v-col>{{ adj.message || "" }}</v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <ModalForm
      :visible="loader.estado"
      @close="loader.estado = false"
      :message="loader.message"
    />
    <viewpdf v-bind:pdfFile="pdfFile"></viewpdf>
  </v-container>
</template>

<script>
import ModalForm from "../modal/ModalForm.vue";
import ViewPdf from './../doc/ViewPdf.vue';
import { formatDate, formatDateUtc } from './../../plugins/filters';

import _ from "lodash";
export default {
  components: {
    ModalForm,
    'viewpdf': ViewPdf
  },
  data: () => ({
    loader: {
      estado: false,
      message: "",
    },
    date_radicado: {
      estado: false,
      value: null,
    },

    expanded: [],
    headers: [
      { text: "Nro radicado", value: "id_rad" },
      { text: "Entidad", value: "dcmnt.dcm_entidad" },
      { text: "Proceso", value: "log_method" },
      { text: "Estado", value: "log_upload" },
      { text: "Mensaje", value: "log_message" },
      { text: "Fecha proceso", value: "log_fecha" },
      { text: "Usuario", value: "usu_nombre_completo" },
      { text: "", value: "data-table-expand" },
    ],
    array_table: [],
    data_impresion: {
      header: [
        {
          name: "Nro Radicado",
          pointer: "id_rad",
          filterButton: true,
        },
        {
          name: "Entidad",
          pointer: "dcm_entidad",
          filterButton: true,
        },
        {
          name: "Proceso",
          pointer: "log_method",
          filterButton: true,
        },
        {
          name: "Estado",
          pointer: "log_upload",
          filterButton: true,
        },
        {
          name: "Mensaje",
          pointer: "log_message",
          filterButton: true,
        },
        {
          name: "Fecha proceso",
          pointer: "log_fecha",
          filterButton: true,
          //format: "date",
        },
        {
          name: "Usuario",
          pointer: "usu_nombre_completo",
          filterButton: true,
        },
      ],
      content: [],
    },
    pdfFile: {},
    timestamp: 0,
    url: '',
  }),
  filters: {
    formatDate
  },
  created() {
    // this.getItems();
    this.fetchTimeStamp();

  },

  methods: {
    getItems() {
      if (!this.date_radicado.value) {
        alert("Por favor seleccionar el rango de fechas.");
        return;
      }

      let d = this.date_radicado.value;

      if (d.length == 1) {
        d.push(d[0]);
      }

      let [date_ini, date_fin] = d;

      this.loader.message =
        "Por favor espere mientras se consultan los datos..";

      this.loader.estado = true;
      let params = `?date_ini=${date_ini}&date_fin=${date_fin}`;
      // let uri = "/logdcmnt/" + String("5dbe7ee91c0e7f4f5c216842") + params;
      let uri = "/logdcmnt/" + String(this.$store.state.company) + params;

      this.axios
        .get(uri, {
          date_ini,
          date_fin,
        })
        .then((response) => {
          console.log(response);
          
          let data = response.data.map((item) => {
            let adjuntos = [];
            if (item.log_adjuntos)
            {
                adjuntos = item.log_adjuntos.map((adj) => {
                let idx = item.dcmnt.adjuntos.find((e) => e._id == adj.id_adj);
                adj = { ...adj, idx };
  
                return adj;
              });
            }

            item.log_adjuntos = adjuntos;
            return item;
          });

          this.array_table = data;
          this.loader.estado = false;
        })
        .catch((error) => {
          console.log("Error get items: ", error);
          this.loader.estado = false;
          this.$emit("msj", { text: error.message });
        });
    },
    async create_file(type = "excel") {
      await this.order_data_print();

      let datos = {
        tipo: type,
        datos: this.data_impresion,
      };

      let new_impresion = new this.gb.Impresion(datos);

      new_impresion.init(
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
    },
    async order_data_print() {
      let data = [];
      for await (const iterator of this.array_table) {
        data.push({
          id_rad: iterator.id_rad,
          dcm_entidad: iterator.dcmnt.dcm_entidad,
          log_method: iterator.log_method,
          log_upload: iterator.log_upload ? "Aceptado" : "Rechazado",
          log_message: iterator.log_message,
          log_fecha: formatDate(iterator.log_fecha),
          usu_nombre_completo: iterator.usu_nombre_completo,
        });

        for await (const iterator2 of iterator.log_adjuntos) {
          data.push({
            id_rad: `  * Archivo ${iterator2.idx.adj_nmro}`,
            dcm_entidad: iterator2.idx.adj_nmbre,
            log_method: "",
            log_upload: iterator2.status ? "Aceptado" : "Rechazado",
            log_message: iterator2.message || "",
            log_fecha: formatDate(iterator2.idx.adj_fecha),
          });
        }
      }

      this.data_impresion.content = _.cloneDeep(data);
    },
    format_date(data = "") {
      let [date, hour] = data.split("T");

      return `${date} ${hour.split(".")[0]}`;
    },
    data_adj(item) {
      let adj =
        item?.dcmnt?.adjuntos?.find((e) => e._id == item.log_id_adj) || {};

      return { ...(adj || {}) };
    },
    isPdf(adj_nmbre){
      if (adj_nmbre){
        var ext = adj_nmbre.substring(adj_nmbre.lastIndexOf('.'));
        if (ext.toLowerCase() == '.pdf'){
          return true;
        }
        return false;
      } else {
        return false;
      }
    },    
    async viewPdf(_id, adjunto){
      try {
        this.loader.message =
          "Por favor espere mientras se muestra el archivo..";
        this.loader.estado = true;

        this.pdfFile = {};
        let path = '/';
        let name = adjunto.adj_nmbre;
        var data = {       
            _id: _id,
            cmp_id: this.$store.state.company,
            path: path,
            name: encodeURIComponent(name)
        }

        let base_url = process.env.NODE_ENV === 'production' ? "" : "http://localhost:4000";

        const response = await this.axios({
          url: `${base_url}/filesystemprovider/pass`,
          method: 'POST',
          data,
          headers: {
            'Authorization': sessionStorage.getItem('jwtToken')
          },
        });

        const token = response?.data?.tk;
        var url = `${base_url}/filesystemprovider/view-file?tk=${encodeURIComponent(token)}`;

        this.pdfFile = {
          url: url,
          adj_nmbre: name
        };

        $('#pdfModal').modal('show');
        this.loader.estado = false;
      } catch(error) {
        this.loader.estado = false;
      }
    },
    downloadFile(_id, adjunto){
      const downloadProgress = () => (progressEvent) => {
        adjunto.downloadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
        //this.$set(adjuntos, x, adjunto);
      }

      adjunto.download = true;
      adjunto.downloadPercentage = 0;
      //this.$set(adjuntos, x, adjunto);

      this.axios({
        url: '/api/download',
        method: 'POST',
        data: { 'cmp_id': this.$store.state.company, '_id': _id, 'usu_cdgo': this.$store.state.user, 'adj_nmbre': adjunto.adj_nmbre, 'temp': false, 'download': true, 'ts': this.timestamp },
        responseType: 'blob',
        onDownloadProgress: downloadProgress().bind(this)            
      })
      //.then(wait(5000))
      .then((response) => {
          adjunto.download = false;
          //this.$set(adjuntos, x, adjunto);
                    
          const url = window.URL.createObjectURL(new File([response.data], adjunto.adj_nmbre, {type: 'application/octet-stream'}));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', adjunto.adj_nmbre);
          //link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();          
      })
      .catch(err => {
          adjunto.download = false;
          //this.$set(adjuntos, x, adjunto);
      });
    }, 
    percentageValue(p){
      return 'width:' + p + '%';
    },    
    fetchTimeStamp(){
      let uri = '/api/timestamp';
      this.axios.post(uri)
      .then((response) => {
        this.timestamp = response.data.timestamp;
      })
      .catch((err) => {
        alert('No se pudo crear el identificador temporal. Consulte con el administrador.');
      });
    },    
  },
};
</script>
